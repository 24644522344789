import { navigate } from 'gatsby';
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import './term-content.scss';

const TermContent = ({ data }) => {
  const redirectHome = () => {
    navigate('/');
  };

  const result = data.allContentfulPagina.edges.filter(e => e.node.node_locale === process.env.LOCALE)[0].node

  return (
    <div className="f-term-internal">
      <div className="f-term-internal-header">
        <i className="fa fa-arrow-left" onClick={redirectHome}></i>
        <div className="f-term-internal-header-title">
          Términos y condiciones
        </div>
      </div>
      <div className="f-term-bread-crumbs">
        <label onClick={redirectHome}>{`HOME / `}</label>TÉRMINOS Y CONDICIONES
      </div>
      <div className="f-term-internal-content">
        {documentToReactComponents(result.description.json)}
      </div>
    </div>
  );
};

export default TermContent;
