import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/organisms/layout/layout';
import TermContent from '../components/organisms/term-content/term-content';

import useMobileMode from '../hooks/useMobileMode';
import favicon from '../images/favicon.ico';

const TermsPage = () => {
  const { isMobile } = useMobileMode();
  const [modalActive, setmodalActive] = useState(null)
  const menuActive = 0;

  const query = useStaticQuery(graphql`
    query TERMS_QUERY {
      allContentfulPagina(filter: { title: { eq: "Términos y condiciones" } }) {
        edges {
          node {
            node_locale
            metaTitle
            metaDescription {
              metaDescription
            }
            description {
              json
              content {
                content {
                  value
                  marks {
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {isMobile ? (
        <Layout menuActive={null} headerActive={false} footerActive={true}
          setmodalActive={setmodalActive}
          modalActive={modalActive}
          title={'Términos y condiciones'}  
          slug={'/terminos-y-condiciones/'}
        >
          <TermContent data={query}></TermContent>
        </Layout>
      ) : (
        <Layout menuActive={menuActive}
          setmodalActive={setmodalActive}
          modalActive={modalActive}
          title={'Términos y condiciones'}  
          slug={'/terminos-y-condiciones/'}
        >
          <TermContent data={query}></TermContent>
        </Layout>
      )}
    </>
  );
};

export default TermsPage;
